import React from 'react'
import SliderPersonnel from './sliderPersonnel'
import IconTwitter from '../assets/images/icon_twitter_gray.svg'
import IconLinkedin from '../assets/images/icon_linkedin_gray.svg'

const Slider = () => {
  return (
      <>
        <div className="section-personnel-slider">
        <div className="slider-overlay"/>
        <SliderPersonnel title="TEAM">
        <div className="slide-content">
            <div className="person-item">
              <div className="col-img">
                <img src="/images/team/Ben.png" alt="Ben Sheppard"/>
              </div>
              <div className="col-info">
                <h3 className="h5 person-name">Ben Sheppard</h3>
                <p className="person-title">Co-founder</p>
                <p>Ben is an experienced founder in the tech and engineering industries. He has led multi-million 
                  dollar project finance transactions in several sectors and countries. He has advised governments 
                  and the private sector, including HSBC, Rockefeller Foundation, and World Bank. He has appeared 
                  on Bloomberg TV and panels at WEF and Google.
                </p>
                <div className="links">
                  <a href="https://www.linkedin.com/in/txshep/" target="_blank" rel="noreferrer"><img src={IconLinkedin} alt="LinkedIn"/></a>
                  <a href="https://twitter.com/tx_shep" target="_blank" rel="noreferrer"><img src={IconTwitter} alt="Twitter"/></a>
                </div>
              </div>
            </div>
          </div>
          <div className="slide-content">
            <div className="person-item">
              <div className="col-img">
                <img src="/images/team/Stanley.png" alt="Stanley Boots"/>
              </div>
              <div className="col-info">
                <h3 className="h5 person-name">Stanley Boots</h3>
                <p className="person-title">Co-founder</p>
                <p>Stanley is a co-founder of Frontier Law & Advisory, an advisory firm in the project finance and
                  infrastructure sectors. He has 22 years of legal experience in project finance, renewable energy and
                  infrastructure development mainly in Southeast Asia. Stanley was previously an infrastructure lawyer
                  with DL Piper and Hogan Lovells, and he is a well-known voice in Vietnam's PPP development. More
                  recently Stanley was engaged by the Asian Development Bank to write the first ever PPP law for
                  Vietnam.</p>
                <div className="links">
                  <a href="https://www.linkedin.com/in/stanley-boots-1b383526/" target="_blank" rel="noreferrer"><img src={IconLinkedin} alt="LinkedIn"/></a>
                  <a href="https://twitter.com/web3stanley" target="_blank" rel="noreferrer"><img src={IconTwitter} alt="Twitter"/></a>
                </div>
              </div>
            </div>
          </div>
          <div className="slide-content">
            <div className="person-item">
              <div className="col-img">
                <img src="/images/team/Henri2.png" alt="Henri Pihkala"/>
              </div>
              <div className="col-info">
                <h3 className="h5 person-name">Henri Pihkala</h3>
                <p className="person-title">Chief Technology Officer</p>
                <p>Henri is a seasoned tech leader and serial entrepreneur with 20 years of experience in high-complexity software
                  platforms—with a particular passion for operating in the intersection of data and finance. He holds an M.Sc. in 
                  Computer Science, majoring in Knowledge Engineering and AI. As CTO, he has led tech teams in algorithmic 
                  high-frequency trading, scalable real-time data processing, and blockchain companies. He has led several startups as 
                  CEO, raising a total of USD 30M+ in funding.</p>
                <div className="links">
                  <a href="https://linkedin.com/in/henripihkala" target="_blank" rel="noreferrer"><img src={IconLinkedin} alt="LinkedIn"/></a>
                  <a href="https://twitter.com/henripihkala" target="_blank" rel="noreferrer"><img src={IconTwitter} alt="Twitter"/></a>
                </div>
              </div>
            </div>
          </div>
          <div className="slide-content">
            <div className="person-item">
            <div className="col-img">
                <img src="/images/team/Aiza.png" alt="Aiza Joyce Pradas"/>
            </div>
            <div className="col-info">
                <h3 className="h5 person-name">Aiza Joyce Pradas</h3>
                <p className="person-title">Operations Manager</p>
                <p>Aiza Joyce has 11 years of experience within general administration, events, HR and recruitment, and has led the incorporation of IT and consultancy
                  companies in Asia and Europe. More recently, she has been acting as a project administrator on two EU grant-funded projects with a web3
                  focus and combined value exceeding 7m€.</p>
                <div className="links">
                <a href="https://www.linkedin.com/in/aizajoycesheppard" target="_blank" rel="noreferrer"><img src={IconLinkedin} alt="LinkedIn"/></a>
                </div>
            </div>
          </div>
        </div>
          <div className="slide-content">
            <div className="person-item">
              <div className="col-img">
                <img src="/images/team/Risto.png" alt="Risto Karjalainen"/>
              </div>
              <div className="col-info">
                <h3 className="h5 person-name">Risto Karjalainen</h3>
                <p className="person-title">Board Member</p>
                <p>Risto is a finance professional with a Ph.D. from the Wharton School. He’s a quant with a long international career in systematic trading and institutional asset management,
                  formally with JP Morgan, Merrill Lynch, and well-known hedge funds in London. Risto is a co-founder as well as the COO of Streamr Network AG.
                  His interests include real-time computing, machine learning, evolutionary algorithms, behavioural finance, and decentralized governance.</p>
                <div className="links">
                  <a href="https://www.linkedin.com/in/ristokarjalainen/" target="_blank" rel="noreferrer"><img src={IconLinkedin} alt="LinkedIn"/></a>
                  <a href="https://twitter.com/ristokarj" target="_blank" rel="noreferrer"><img src={IconTwitter} alt="Twitter"/></a>
                </div>
              </div>
            </div>
          </div>
        </SliderPersonnel>
        </div>

        {/* STRATEGIC ADVISORS */}
        <div className="section-personnel-slider">
        <div className="slider-overlay"/>
        <SliderPersonnel title="STRATEGIC ADVISORS">

        <div className="slide-content">
            <div className="person-item">
              <div className="col-img">
                <img src="/images/team/PaulDeCordova.png" alt="Paul de Cordova"/>
              </div>
              <div className="col-info">
                <h3 className="h5 person-name">Paul de Cordova</h3>
                <p className="person-title">Strategic Advisor</p>
                <p>A commercial lawyer for 30+ years, Paul has led UK and US international law firms and been the in-house counsel
                  of a major UK-based construction company. His consultancy PDC Advisory LLP provides strategic business advice on corporate
                  and commercial matters. He also leads the built environment practice of virtual law firm Jurit LLP. Paul has been involved
                  in the structuring and delivery of legally complex high-value energy, transport and public infrastructure projects and has worked
                  on transactions across Europe, South Asia, the Far East, the Middle East and Africa.</p>
                <div className="links">
                  <a href="https://www.linkedin.com/in/paul-de-cordova-16a346a/" target="_blank" rel="noreferrer"><img src={IconLinkedin} alt="LinkedIn"/></a>
                </div>
              </div>
            </div>
          </div>

          <div className="slide-content">
            <div className="person-item">
              <div className="col-img">
                <img src="/images/team/Beryl.png" alt="Beryl Chavez Li"/>
              </div>
              <div className="col-info">
                <h3 className="h5 person-name">Beryl Chavez Li</h3>
                <p className="person-title">Strategic Advisor</p>
                <p>Beryl is the co-founder of Yield Guild Games. Her previous experiences include founding asset token platform CapchainX; being Entrepreneur in Residence at Coins.ph, a crypto exchange and payments startup acquired by GoJEk; backtesting FX trading algorithms at BlackRock London; product owning an investment platform at Unionbank PH, where she is currently Advisor; and authoring the Philippine Roadmap for Digital Startups. Beryl's career started in early-stage Venture Capital in Shanghai in 2012, and she holds a Master of Finance degree from Cambridge University.</p>
                <div className="links">
                  <a href="https://www.linkedin.com/in/beryl-chavez-li-832baa4b/" target="_blank" rel="noreferrer"><img src={IconLinkedin} alt="LinkedIn"/></a>
                  <a href="https://twitter.com/berylchavezli" target="_blank" rel="noreferrer"><img src={IconTwitter} alt="Twitter"/></a>
                </div>
              </div>
            </div>
          </div>

          <div className="slide-content">
            <div className="person-item">
              <div className="col-img">
                <img src="/images/team/Michael.png" alt="Michael Malka"/>
              </div>
              <div className="col-info">
                <h3 className="h5 person-name">Michael Malka</h3>
                <p className="person-title">Strategic Advisor</p>
                <p>Michael has been part of the software industry for over 20 years in various roles, from developer, to specialist, to CEO, most recently as
                  strategic advisor to the Streamr project and CTO of TX – Tomorrow Explored, a web3 software consulting and development company. Relevant projects
                  include building financial creditworthiness products to help unbanked individuals gain access to DeFi microfinance. Michael has co-founded three
                  companies and his current interests include blockchain and artificial intelligence.</p>
                <div className="links">
                  <a href="https://www.linkedin.com/in/michael-malka/" target="_blank" rel="noreferrer"><img src={IconLinkedin} alt="LinkedIn"/></a>
                  <a href="https://twitter.com/michaelmalka" target="_blank" rel="noreferrer"><img src={IconTwitter} alt="Twitter"/></a>
                </div>
              </div>
            </div>
          </div>
          <div className="slide-content">
            <div className="person-item">
              <div className="col-img">
                <img src="/images/team/eddy_travia.png" alt="Eddy Travia"/>
              </div>
              <div className="col-info">
                <h3 className="h5 person-name">Eddy Travia</h3>
                <p className="person-title">Strategic Advisor</p>
                <p>Eddy is a pioneer investor in blockchain technology companies and the co-founder and CEO
                   of Coinsilium Group, a publicly listed blockchain and open finance venture builder. In 2013,
                   after 9 years as a private equity fund manager in Greater China, Eddy co-founded the world’s first
                   global incubator of blockchain startups. In 2014, he was named among the ‘Top 3 Most Influential
                   Investors’ at the Blockchain Awards along with Roger Ver and Marc Andreessen. In 2015, Coinsilium
                   Group became the first blockchain company to IPO. Eddy has led investments in more than 25 blockchain
                   companies globally and advised more than 25 token offerings.</p>
                <div className="links">
                  <a href="https://www.linkedin.com/in/niftyeddy/" target="_blank" rel="noreferrer"><img src={IconLinkedin} alt="LinkedIn"/></a>
                  <a href="https://twitter.com/niftyeddy" target="_blank" rel="noreferrer"><img src={IconTwitter} alt="Twitter"/></a>
                </div>
              </div>
            </div>
          </div>
          <div className="slide-content">
            <div className="person-item">
              <div className="col-img">
                <img src="/images/team/Jan.png" alt="Jan Van Schoonhoven"/>
              </div>
              <div className="col-info">
                <h3 className="h5 person-name">Jan Van Schoonhoven</h3>
                <p className="person-title">Strategic Advisor</p>
                <p>Jan has worked over 15 years in the Public and Private Partnership (PPP), dealing with roads, water and social sector, starting as Director
                  PPP Unit of the Netherlands. He has worked extensively with institutions such as the European Investment Bank (EIB), multilateral organisations
                  (United Nations) and the World Bank. Jan holds considerable experience in management and administration of over 20 PPP projects with a budget
                  of 5bn€ within the EU and emerging markets. Currently he is a principal advisor at the European Bank for Reconstruction and Development (EBRD)
                  in London.</p>
                <div className="links">
                  <a href="https://www.linkedin.com/in/vanschoonhoven/" target="_blank" rel="noreferrer"><img src={IconLinkedin} alt="LinkedIn"/></a>
                </div>
              </div>
            </div>
          </div>
          <div className="slide-content">
            <div className="person-item">
              <div className="col-img">
                <img src="/images/team/PeterLembrechts.png" alt="Peter Lembrechts"/>
              </div>
              <div className="col-info">
                <h3 className="h5 person-name">Peter Lembrechts</h3>
                <p className="person-title">Strategic Advisor</p>
                <p>Peter is Head of Concessions & Assets, Strategy & Transformation for BESIX in the Middle East. Since 2014, he has been building an effective business structure and team to become a flagship for BESIX’ diversification strategy. With extensive experience of the development, negotiation and implementation of PPP projects, his career in the Middle East has been marked by a focus on business models that consider waste as a valuable resource, rather than a problem. He has previously held finance positions in a real estate developer and in a large Belgian bank.</p>
                <div className="links">
                  <a href="https://www.linkedin.com/in/peter-lembrechts-9535931/" target="_blank" rel="noreferrer"><img src={IconLinkedin} alt="LinkedIn"/></a>
                </div>
              </div>
            </div>
          </div>
          <div className="slide-content">
            <div className="person-item">
              <div className="col-img">
                <img src="/images/team/Robin_Nordblad.png" alt="Robin Nordblad"/>
              </div>
              <div className="col-info">
                <h3 className="h5 person-name">Robin Nordblad</h3>
                <p className="person-title">Strategic Advisor</p>
                <p>Robin is a corporate lawyer who is focused on capital markets transactions and securities regulatory matters. He is the head of capital markets at Avance Attorneys in Finland and has spent a significant part of his 20-year career in the corporate and capital markets practice of a top-tier U.S. law firm in London and New York and in a global investment bank. He also has significant experience in legal matters relating to investment funds. Robin has a particular interest in developing a legal framework for decentralized finance.</p>
                <div className="links">
                  <a href="https://www.linkedin.com/in/robin-nordblad-8510b91a/" target="_blank" rel="noreferrer"><img src={IconLinkedin} alt="LinkedIn"/></a>
                </div>
              </div>
            </div>
          </div>          
          <div className="slide-content">
            <div className="person-item">
              <div className="col-img">
                <img src="/images/team/david.png" alt="David Crosbie"/>
              </div>
              <div className="col-info">
                <h3 className="h5 person-name">David Crosbie</h3>
                <p className="person-title">Strategic Advisor</p>
                <p>For 20+ years David has advised multilateral development banks (World Bank, IFC, EIB, EBRD, ADB), the UN,
                  national governments and civil society organisations in 40+ countries to deliver social and environmental safeguards,
                  poverty reduction and climate mitigation, often in the context of fragile or small island developing states or rapid urbanisation.
                  He’s the founder of Penumbra, Senior Advisor for the UN, and ADB Team Leader for delivering $600m climate-resilient affordable
                  housing in Mongolia. He also devises social and environmental frameworks for a bilateral agency.</p>
                <div className="links">
                  <a href="https://www.linkedin.com/in/davidcrosbie1/" target="_blank" rel="noreferrer"><img src={IconLinkedin} alt="LinkedIn"/></a>
                </div>
              </div>
            </div>
          </div>
        </SliderPersonnel>
      </div>
</>
  )
}

export default Slider