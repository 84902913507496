import React from 'react'

const Numbers = () => {
  return (
    <section className="section-numbers">
      <div className="container">
        <div className="section-numbers-content">
            <div className='d-xs-none d-sm-block desktop-container'>
            <div className='row'>
                    <div className='col-md-11 col-lg-10 col-xl-10 col-xxl-7'>
                        <p className='section-title-main'>
                        The Silta mission is to bridge the infrastructure gap.
                        </p>
                    </div>
                </div>
                <div className='container pb-5'>
                <div className='row row1 mt-5' data-sal="fade" data-sal-delay="200" data-sal-easing="ease">
                    <div className='col-md-0 col-xl-4 col-xxl-3' />
                    <div className='col-md-8'>
                        <div className='title-container w-100'>
                        <p className='main-title'>$94 Trillion</p>
                        </div>
                        <p className='main-text'>OECD's estimate of the infrastructure gap by 2040</p>
                    </div>
                </div>

                <div className='row my-5 d-xs-none d-md-flex' />

                <div className='row row2' data-sal="fade" data-sal-delay="200" data-sal-easing="ease">
                    <div className='col-md-10 col-lg-8 col-xl-6 col-xxl-6 ml-5 d-flex flex-column'>
                        <div className='title-container'>
                            <p className='main-title'>$100 Billion</p>
                        </div>
                        <p className='main-text pr-xl-4'>ADB's commitment to Green Financing through 2030</p>
                    </div>
                    <div className='col-sm-6' />
                </div>
                    <div className='row my-5' />

                    <div className='row row3' data-sal="fade" data-sal-delay="200" data-sal-easing="ease">
                        <div className='col-md-1 col-xl-4 col-xxl-4'/>
                        <div className='col-md-11 col-xl-8 col-xxl-8'>
                            <div className='d-flex align-items-end'>
                                <div className='d-flex align-items-start flex-column'>
                                    <div className='title-container mb-0'>
                                        <p className='main-title mb-0'>3 hours vs</p>
                                    </div>
                                    <div className='title-container double-line-mg'>
                                        <p className='main-title mt-0'>6 months</p>
                                    </div>
                                </div>
                            </div>
                            <p className='main-text'>Silta AI's ability to conduct complex due diligence compared to traditional methods</p>
                        </div>
                    </div>

                    <div className='row my-5' />

                    <div className='row row4' data-sal="fade" data-sal-delay="200" data-sal-easing="ease">
                        <div className='col-xl-2 col-xxl-3 col-md-2 col-lg-2'/>
                        <div className='col-md-10 col-lg-10 col-xl-6 col-xxl-3'>
                            <div className='title-container'>
                                <p className='main-title'>80%</p>
                            </div>
                            <p className='main-text pr-xl-4'>Potential due diligence cost savings using Silta AI</p>
                        </div>
                        {/*
                        <div className='col-md-6 pl-md-3 col-lg-4 col-xl-3 col-xxl-3 d-flex align-items-end'>
                            <div className='source-text padding-none'>
                                **Source: <a href="https://www.refinitiv.com/en/infrastructure-investing/insights/sustainable-infrastructure" target="_blank" rel="noreferrer">Refinitiv: The Sustainable Infrastructure Investment Report</a>
                            </div>
                        </div>
                        */}
                    </div>
                </div>
                </div>

                {/* MOBILE VIEW */}
                <div className='d-flex flex-column align-items-start d-xs-flex d-md-none'>
                    <div className='row'>
                        <div className='col-12'>
                            <p className='section-title-main mb-5'>
                            The Silta mission is to bridge the infrastructure gap.
                            </p>
                        </div>
                    </div>

                    <div className='px-5 container'>
                        <div className='row row5 ml-0'>
                            <div className='col-xs-11 xs-number-column'>
                                <div className='title-container w-100'>
                                <p className='main-title'>$94 Trillion</p>
                                </div>
                                <p className='main-text'>OECD's estimate of the infrastructure gap by 2040</p>
                            </div>
                        </div>
                        <div className='row w-100 mt-4 ml-0 row6'>
                            <div className='col-xs-11 xs-number-column w-100'>
                                <div className='title-container'>
                                <p className='main-title'>$100 Billion</p>
                                </div>
                                <p className='main-text'>ADB's commitment to Green Financing through 2030</p>
                                {/* keeping source markup here in case needed later
                                <div className='source-text mt-1'>
                                    *Source: <a href="https://infrastructure.aecom.com/infrastructure-funding" target='_blank' rel="noreferrer">AECOM: The Infrastructure Gap: Financing and Funding the Future</a>
                                </div>
                                 */}
                            </div>
                        </div>

                        <div className='row w-100 mt-4 ml-0 row7'>
                            <div className='col-xs-11 xs-number-column w-100'>
                                <div className='d-flex align-items-end'>
                                    <div className='d-flex align-items-start flex-column'>
                                        <div className='title-container mb-0'>
                                            <p className='main-title mb-0'>3 hours vs</p>
                                        </div>
                                        <div className='title-container double-line-mg'>
                                            <p className='main-title mt-0'>6 months</p>
                                        </div>
                                    </div>
                                </div>
                                <p className='main-text'>Silta AI's ability to conduct complex due diligence compared to traditional methods</p>
                            </div>
                        </div>

                        <div className='row w-100 mt-4 ml-0 row8'>
                            <div className='col-xs-11 xs-number-column w-100'>
                                <div className='title-container'>
                                <p className='main-title'>80%</p>
                                </div>
                                <p className='main-text'>Potential due diligence cost savings using Silta AI</p>
                                {/*
                                <div className='source-text mt-1'>
                                    **Source: <a href="https://www.refinitiv.com/en/infrastructure-investing/insights/sustainable-infrastructure" target='_blank' rel="noreferrer">Refinitiv: The Sustainable Infrastructure Investment Report</a>
                                </div>
                                 */}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
  )
}

export default Numbers