import React, { useEffect } from 'react'
import Layout from '../components/layout'
import Seo from '../components/seo'
import Hero from '../components/hero'
import Main from '../components/main'
import Numbers from '../components/numbers'
import WorkWithUs from '../components/workWithUs'
import Newsletter from '../components/newsletter'
import Contact from '../components/contact'
import Team from '../components/team'
import Loadable from '@loadable/component'
import ScrollToTop from '../components/ScrollToTop'

// const SliderRoadmapComponent = Loadable(() => import("../components/sliderRoadmap"))

const AboutUs = () => {

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  return (
    <Layout>
        <Seo title="About us" />
        <ScrollToTop />
        <div className='fixed-header'>
        <Hero />
        </div>
        <Main
        title="It's time to revolutionise infrastructure financing."
        text="Infrastructure needs to be decarbonised to meet the Paris Agreement goals and to limit global warming to 1.5°C by 2030. Trillions of dollars of infrastructure investment are needed to meet this target."
        text2="Silta AI bridges the gap by empowering critical smaller-scale projects to secure funding through precise, data-driven insights. By streamlining due diligence and sustainability assessments, Silta AI ensures that projects of all sizes can demonstrate their viability and attract investment from a global network of financiers."
        />
        <Numbers />
        {/* <SliderRoadmapComponent /> */}
        <Team />
        {/*<WorkWithUs />*/}
        <Newsletter />
        <Contact />
    </Layout>
  )
}

export default AboutUs